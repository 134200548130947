// ============================================================================
// PDFPreview
// ----------
// PDFPreview helper
// ============================================================================

// -------
// Imports
// -------
import 'intersection-observer'

import { dragscroll } from 'vue-dragscroll'

// ---------
// Internals
// ---------
const Private = {
	instances: new WeakMap(),
	createObserver: function (el, vnode, modifiers, callback) {
		const observer = new IntersectionObserver(entries => {
			const entry = entries[0]
			if (entry.isIntersecting) {
				callback()
				if (modifiers.once) {
					observer.disconnect()
				}
			}
		})
		// Observe when element is inserted in DOM
		vnode.context.$nextTick(() => observer.observe(el))
		return observer
	},
	bind: function (el, { value, modifiers }, vnode) {
		if (typeof window.IntersectionObserver !== 'undefined') {
			const observer = Private.createObserver(el, vnode, modifiers, () => {
				const callback = value
				if (typeof callback === 'function') {
					callback()
				}
			})
			Private.instances.set(el, { observer })
		}
	},
	update: function (el, { value, oldValue }, vnode) {
		if (value !== oldValue) {
			const { observer } = Private.instances.get(el)
			observer.disconnect()
			Private.bind(el, { value }, vnode)
		}
	},
	unbind: function (el) {
		if (Private.instances.has(el)) {
			const { observer } = Private.instances.get(el)
			observer.disconnect()
			Private.instances.delete(el)
		}
	},
	inserted: function (el, binding) {
		const callback = binding.value
		if (binding.modifiers.immediate) {
			callback()
		}
		el.addEventListener('scroll', () => Private.throttle(callback, 300), true)
	},
	throttle: function (callback, limit) {
		let waiting = false
		if (!waiting) {
			callback.apply(this, arguments)
			waiting = true
			setTimeout(function () {
				waiting = false
			}, limit)
		}
	}
}

// -------
// Exports
// -------
export default {
	constants: {
		PIXEL_RATIO: window.devicePixelRatio || 1,
		VIEWPORT_RATIO: 0.98
	},
	directives: {
		dragscroll: dragscroll,
		visible: {
			bind: Private.bind,
			update: Private.update,
			unbind: Private.unbind
		},
		scroll: {
			inserted: Private.inserted
		}
	}
}
